<style scoped>
	.loading_box, .template_box {width: 100%;height: 100%;overflow-y: auto;}
	/* 物业公司 */
	.property_company-box {display: flex;flex-direction: row;padding: 2rem 3%;align-items: center;}
	.property_company-image-box img {width: 5rem;height: 5rem;}
	.property_company-info-name {padding-left: 2rem;font-size: 2rem}
	.welcome{padding: 20px;font-size: 1.2rem;}
</style>

<template>
	<div class="loading_box" v-loading="pageLoading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
	 element-loading-background="rgba(255, 255, 255, 1)">
		<div class="template_box">
			<div class="property_company-box">
				<div class="property_company-image-box">
					<img src="../assets/icon/dtx.png" alt="">
				</div>
				<div class="property_company-info-box">
					<div class="property_company-info-name">{{company_name}}</div>
				</div>
			</div>
			<div class="welcome">欢迎使用晴朗社区物业管理系统！</div>
			<!-- 小区列表 -->
			<div class="residential_quarters-list">
				<!-- <el-table :data="tableData" style="width: 100%" border="">
					<el-table-column prop="d_name" label="小区" width=""></el-table-column>
					<el-table-column prop="c_num" label="房屋数量/户" width=""></el-table-column>
					<el-table-column prop="p_num" label="居民人数/人"></el-table-column>
					<el-table-column prop="w_num" label="工作人员/人"></el-table-column>
					<el-table-column prop="ds_price" label="电商流水/元"></el-table-column>
				</el-table> -->
			</div>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		name: 'homePage',
		data() {
			return {
				pageLoading: true,
				company_name: localStorage.getItem("companyName"),
				tableData:[{
					d_name:'小区1',
					c_num:1000,
					p_num:1000,
					w_num:50,
					ds_price:"3000.20",
					
				}]
			}
		},
		created: function() {
			_this = this;
			_this.pageLoading = false;
		},
		mounted: function() {

		},
		methods: {
			/* 退出登录 */

		}
	}
</script>
